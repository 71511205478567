<template>
    <main class="models-page">

        <section class="border-bottom bg-lighter md-up:sticky-top" style="top: 70px;">
            <div class="container py-3">
                <nav class="nav sm-down:flex-column">

                    <router-link :to="{name: 'Round_Post', params: {arr_id}}" class="btn btn-sm btn-light text-left d-block sm-down:w-fill">
                        <i class="i-package float-left"></i>
                        <span>{{ $t('new_round') }}</span>
                    </router-link>

                    <router-link :to="{name: 'Rounds_Map', params: {arr_id}}" class="btn btn-sm btn-light text-left d-block sm-down:d-none">
                        <i class="i-map float-left"></i>
                        <span>{{ $t('open_map') }}</span>
                    </router-link>

                    <span class="ml-auto btn btn-sm btn-danger p-0 d-block sm-down:w-fill"
                          data-click="ignore"
                          @click="delete_confirm"
                          v-if="selected.length > 0">
                        <i class="i-trash"></i>
                    </span>
                </nav>
            </div>
        </section>

        <section>
            <div class="container py-5">

                <div class="row">
                    <div class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                         v-for="(round, i) in list"
                         :ref="'col-'+round.idt_round"
                         :key="i">
                        <round-card :data="round"
                                    selectable
                                    @select-force="onSelectForce"
                                    @remove="onRemove"
                                    @select="onSelect"/>
                    </div>
                </div>

                <mega-loading :identifier="loadingId" @infinite="loadPage"></mega-loading>
            </div>
        </section>

        <delete-confirm ref="delete-confirm"
                        data-click="ignore"
                        :title="$t('round_del_title')"
                        :text="$t('round_del_confirm')"
                        @confirm="delete_rounds"
                        :rounds="selected">

            <span class="badge m-2" v-for="(id, i) in selected" :key="i">#{{id}}</span>
        </delete-confirm>
    </main>
</template>

<script>
    import RoundCard from './components/round-card'
    import DeleteConfirm from '../../components/delete-confirm'

    export default {
        name: 'RoundsPage',
        props: ['arr_id'],
        data() {
            return {
                rounds: [],
                selected: [],
                keys: {},
                length: 0,
                loaded: false,
                loading: true,
                showModal: false,
				multiple: false,
				loadingId: +new Date()
            }
        },
        watch: {
            '$route'() {
                this.$root.list.splice(0, this.$root.list.length);
                this.loadingId += 1;
            }
        },
        computed: {
            list() {
                return this.$root.list;
            },
		},
		created() {
			if( !this.$root['history'].includes(this.$route.name) )
				this.$root.list.splice(0, this.$root.list.length);
		},
        mounted() {

            this.$navbar.name = 'Rounds';

            document.addEventListener("click", (e) => {

                let p = e.target;

                while(p)
                {
                    if(p && p.dataset.type === 'round-card'
                        || p && p.dataset.click === 'ignore')
                        return;

                    p = p.parentElement;
                }

                this.selected = [];
            });

            document.addEventListener("keyup", this.onKeyup);
            document.addEventListener("keydown", this.onKeydown);
        },
        methods: {

            onKeyup(e) { this.keys[e.key] = false },
            onKeydown(e) { this.keys[e.key] = true },

            delete_confirm() { this.$refs['delete-confirm'].open() },

            onSelect(roundId) {

                if(!this.keys['Control'])
                    this.selected = [];

                if(!this.selected.includes(roundId))
                    this.selected.push(roundId);
            },

            onRemove(roundId) {

                if(!this.keys['Control']) this.onSelect(roundId)
                else this.selected = this.selected.filter((r) => r !== roundId);
            },

            onSelectForce(roundId) {

                if(!this.selected.includes(roundId))
                    this.selected.push(roundId);
                else this.selected = this.selected.filter((r) => r !== roundId);
            },

            async delete_rounds() {
                try {
                    for (const id of this.selected) {
                        await this.$api.v2.delete('/round', { params: { idt_round: id } });
                        this.$alert.success(this.$t("round_deleted"))
                        this.$root.list = this.$root.list.filter(el => el.idt_round !== id);
                        this.$refs['col-' + id][0].outerHTML = '';
                    };

                    this.selected.splice(0, this.selected.length);

                    this.$refs['delete-confirm'].close();
                } catch (error) {
                    this.$alert.danger(this.$t("err_server"))
                }
            },

            loadPage($state) {
                this.loading = true;

                let params = {
                    limit: 20,
                    idt_arp: this.arr_id,
                    offset: this.list.length,
                    is_location_hidden: true
                };

                this.$api.v2.get('/user/roundList', {params})
                    .then(({data}) => {
                        this.length = data.count;
                        this.loaded = true;
						this.loading = false;

                        this.$navbar.name = `${ this.$t('rounds') } (${ this.list.length })`;

                        if(data.rounds.length){
                            setTimeout(() => { if ($state) $state.loaded() }, 500);
                            this.$root.list.push(...data.rounds);
                        }
                        else $state.complete();
					})
					.catch(({ response }) => {
						if (response.status === 404) $state.complete();
                        else this.$alert.danger('Oops.. Server error')
                    })
            }
        },
        components: {
            RoundCard,
            DeleteConfirm
        }
    }
</script>
