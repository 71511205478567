var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c(
              "nav",
              { staticClass: "nav sm-down:flex-column" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                    attrs: {
                      to: { name: "Round_Post", params: { arr_id: _vm.arr_id } }
                    }
                  },
                  [
                    _c("i", { staticClass: "i-package float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("new_round")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:d-none",
                    attrs: {
                      to: { name: "Rounds_Map", params: { arr_id: _vm.arr_id } }
                    }
                  },
                  [
                    _c("i", { staticClass: "i-map float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("open_map")))])
                  ]
                ),
                _vm._v(" "),
                _vm.selected.length > 0
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "ml-auto btn btn-sm btn-danger p-0 d-block sm-down:w-fill",
                        attrs: { "data-click": "ignore" },
                        on: { click: _vm.delete_confirm }
                      },
                      [_c("i", { staticClass: "i-trash" })]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container py-5" },
          [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.list, function(round, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    ref: "col-" + round.idt_round,
                    refInFor: true,
                    staticClass:
                      "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                  },
                  [
                    _c("round-card", {
                      attrs: { data: round, selectable: "" },
                      on: {
                        "select-force": _vm.onSelectForce,
                        remove: _vm.onRemove,
                        select: _vm.onSelect
                      }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("mega-loading", {
              attrs: { identifier: _vm.loadingId },
              on: { infinite: _vm.loadPage }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "delete-confirm",
        {
          ref: "delete-confirm",
          attrs: {
            "data-click": "ignore",
            title: _vm.$t("round_del_title"),
            text: _vm.$t("round_del_confirm"),
            rounds: _vm.selected
          },
          on: { confirm: _vm.delete_rounds }
        },
        _vm._l(_vm.selected, function(id, i) {
          return _c("span", { key: i, staticClass: "badge m-2" }, [
            _vm._v("#" + _vm._s(id))
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }